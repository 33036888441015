const DEFAULTS = {
  avatarStyle: 'Circle',
  topType: 'ShortHairShortFlat',
  accessoriesType: 'Blank',
  hairColor: 'Black',
  facialHairType: 'BeardLight',
  facialHairColor: 'BrownDark',
  clotheType: 'Hoodie',
  clotheColor: 'Heather',
  eyeType: 'Default',
  eyebrowType: 'Default',
  mouthType: 'Twinkle',
  skinColor: 'Light',
}

const MOODS = {
  happy: {
    ...DEFAULTS,
  },
  happyTalking: {
    ...DEFAULTS,
    mouthType: 'Smile',
  },
  sad: {
    ...DEFAULTS,
    eyebrowType: 'SadConcerned',
    mouthType: 'Sad',
  },
  sadTalking: {
    ...DEFAULTS,
    eyebrowType: 'SadConcerned',
    mouthType: 'Concerned',
  },
  surprised: {
    ...DEFAULTS,
    eyeType: 'Surprised',
    eyebrowType: 'RaisedExcited',
    mouthType: 'Serious',
  },
  surprisedTalking: {
    ...DEFAULTS,
    eyeType: 'Surprised',
    eyebrowType: 'RaisedExcited',
    mouthType: 'Concerned',
  },
  inLove: {
    ...DEFAULTS,
    eyeType: 'Hearts',
    eyebrowType: 'RaisedExcited',
    mouthType: 'Smile',
  },
  inLoveTalking: {
    ...DEFAULTS,
    eyeType: 'Hearts',
    eyebrowType: 'RaisedExcited',
  },
  angry: {
    ...DEFAULTS,
    eyeType: 'Surprised',
    eyebrowType: 'Angry',
    mouthType: 'Sad',
  },
  angryTalking: {
    ...DEFAULTS,
    eyeType: 'Surprised',
    eyebrowType: 'Angry',
    mouthType: 'Concerned',
  },
  thinking: {
    ...DEFAULTS,
    eyeType: 'EyeRoll',
    eyebrowType: 'UpDown',
    mouthType: 'Serious',
  },
  sick: {
    ...DEFAULTS,
    eyeType: 'Squint',
    eyebrowType: 'SadConcerned',
    mouthType: 'Vomit',
    skinColor: 'Pale',
  },
  dead: {
    ...DEFAULTS,
    eyeType: 'Dizzy',
    eyebrowType: 'RaisedExcited',
    mouthType: 'Disbelief',
    skinColor: 'Pale',
  },
  sleeping: {
    ...DEFAULTS,
    eyeType: 'Close',

  },
  eating: {
    ...DEFAULTS,
    eyeType: 'Happy',
    mouthType: 'Eating',
  },
}

const ALL_MOODS = Object.keys(MOODS)

export default MOODS
export { ALL_MOODS as allMoods }