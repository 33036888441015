import { css, keyframes } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMin, mqMax } from '~/styles/mixins/mediaQueries'

const floatInAnimation = keyframes`
  from {
    transform: translateX(-100%) translateY(-100%) rotate(15deg);
  }
  to {
    transform: translateX(-30%) translateY(-10%) rotate(15deg);
  }
`

const floatHoverAnimation = keyframes`
  0% {
    transform: translateX(-30%) translateY(-10%) rotate(15deg);
  }
  100% {
    transform: translateX(-35%) translateY(-20%) rotate(10deg);
  }
`

export const sectionIntro = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: ${colors.theme[theme].backgroundColorAlt};
  overflow: hidden;
  padding-bottom: 5rem;
  height: 0; // Use the section's min height

  ${mqMin.md} {
    height: 93vh;
  }

  ${mqMax.md} {
    flex-direction: column;
  }
`

export const blobSignatureContainer = css`
  height: 100%;
  width: 100%;

  div {
    height: 100%;
    margin: 0 auto;
  }
 
  ${mqMin.md} {
    max-height: 25rem;
    width: 50%;
 }

 ${mqMax.md} {
    height: 50%;
 }
`

export const avatarContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 25rem;
  width: 50%;

  > div {
    width: 100%;
    height: 100%;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  ${mqMax.md} {
    height: 50%;
    width: 100%;
  }
`

export const divider = css`
  position: absolute;
  bottom: -3.5rem;

  ${mqMax.md} {
    bottom: -3rem;
  }
`

export const morphingBlobs = css`
  width: 50%;
  max-width: 45rem;

  ${mqMax.md} {
    height: auto;
    width: 100%;
  }
`

export const versionIndicator = css`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-weight: 500;
  opacity: 0.5;
  user-select: none;
`

export const watchingWiiImage = css`
  position: absolute;
  width: 30%;
  max-width: 25rem;
  top: 0;
  left: 0;
  transform: translateX(-100%) translateY(-100%) rotate(15deg);
  animation: ${floatInAnimation} 5s ease-out 1s forwards, ${floatHoverAnimation} 5s ease-in-out 6s infinite alternate;
  transition: scale 0.25s ease-in-out;

  &:hover {
    scale: 1.1;
    cursor: pointer;
  }
`
