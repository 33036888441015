import { css } from '@emotion/core'
import { transparentize } from 'polished'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionExperience = ({ theme }) => css`
  background: ${colors.theme[theme].backgroundColor};
`

export const experiencePicker = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 60rem;
  margin: 0 auto;
`

export const experience = isSelected => css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  min-width: 9rem;
  flex: 1;
  font-size: 0.75rem;
  margin-bottom: 2rem;
  opacity: 0.25;
  cursor: pointer;
  transition: 0.2s;

  > img {
    height: 100%;
    max-width: 4rem;
    margin-bottom: 1rem;

    ${mqMax.md} {
      width: 75%;
    }
  }

  ${mqMax.md} {
    min-width: 7rem;
  }

  ${!isSelected && `
    &:hover {
      opacity: 0.5;
      transform: scale(1.2) !important;
      filter: drop-shadow(1rem 1rem 0.4rem ${colors.gray});
    }
  `};

  ${isSelected && `
    cursor: default;
    opacity: 1;
  `};
`

export const descriptionContainer = css`
  position: relative;
  min-height: 20rem;
  height: auto;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;

  ${mqMax.md} {
    min-height: 26rem;
    text-align: center;
  }
`

export const description = css`
  position: absolute;
  left: 0;
  top: 0;
`

export const tagsContainer = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  ${mqMax.md} {
    justify-content: center;
  }
`

export const tagChip = color => css`
  border: 0.1rem solid ${colors[color]};
  background: ${transparentize(0.5, colors[color])};
  padding: 0rem 0.65rem 0.1rem 0.65rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  color: ${colors.white};
  
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const resumeLinkContainer = css`
  margin: 5rem 0;
  font-size: 4rem;
  text-align: center;
  font-weight: bold;

  ${mqMax.md} {
    font-size: 250%;
    margin-top: 30%;
  }

  ${mqMax.sm} {
    margin-top: 65%;
  }
`
