import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { transparentize, darken } from 'polished'

const COLOR_PROFILES = {
  white: {
    background: colors.white,
    font: colors.black,
    shadow: colors.gray,
  },
}

const getColorProfile = (color) => {
  let colorProfile = COLOR_PROFILES[color]

  if (!colorProfile) {
    colorProfile = {
      background: colors[color],
      font: colors.white,
      shadow: transparentize(0.5, colors[color]),
    }
  }

  return colorProfile
}

export const button = color => {
  const { background, font, shadow } = getColorProfile(color)

  return css`
    border: none;
    outline: none;
    background: ${background};
    font-size: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 0.3rem;
    box-shadow: 0rem 0rem 0.5rem 0.1rem ${shadow};
    color: ${font};
    cursor: pointer;
    transition: 0.1s;

    &:hover {
        transform: scale(1.1);
        background: ${darken(0.1, background)};
    }

    &:active {
        transform: scale(1);
        background: ${darken(0.2, background)};
    }
`
}