import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionFooter = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 15vh !important;
  font-size: 1.75rem;
  font-weight: bold;
  background: ${colors.theme[theme].backgroundColor};

  ${mqMax.md} {
    min-height: unset !important;
  }
`

export const content = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2rem;
  width: 100%;
  max-width: 50rem;

  ${mqMax.md} {
    max-width: unset;
  }
`

export const animationContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;

  > span {
    margin-top: 0.5rem;
  }

  ${mqMax.md} {
    width: unset;
  }
`

export const animation = css`
  height: 5rem;
  width: 5rem;

  ${mqMax.md} {
    height: 4rem;
    width: 4rem;
  }
`
