import React, { useState } from 'react'

import { useSpring, useTransition, animated } from 'react-spring'
import { FaGithubSquare, FaPhoneSquare, FaEnvelopeSquare, FaLinkedin } from 'react-icons/fa'
import Animation from '~/components/Animation'

import * as style from './styles'

const CONTACT_INFO = [ // todo: Move all to fixtures
  { key: 'linkedIn', Icon: FaLinkedin, color: 'linkedin', value: 'Joseph Tawk', href: 'https://www.linkedin.com/in/joseph-tawk-750091171/' },
  { key: 'github', Icon: FaGithubSquare, color: 'orange', value: 'DovahBrownies', href: 'https://github.com/DovahBrownies' },
  { key: 'phone', Icon: FaPhoneSquare, color: 'green', value: '+1 (951) 518-2127', href: 'tel: +1 (951) 518-2127' },
  { key: 'email', Icon: FaEnvelopeSquare, color: 'blue', value: 'joseph.taouk@divz.io', href: 'mailto:joseph.taouk@divz.io' },
]

/**
 * <Describe the Card component here>
 *
 * @component
 * @usage `import Card from '~/components/Card'`
 * @example
 * <Add an example of how Card is used here>
 */
const CardComponent = ({ theme, ...extra }) => {
  const [isFlipped, set] = useState(false)
  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })
  const isDarkTheme = theme === 'dark'

  const CardBackContent = () => (
    <div css={style.logoContainer}>
      <Animation name={isDarkTheme ? 'signatureStaticWhite' : 'signatureStatic'} />
    </div>
  )

  const CardFrontContent = () => {
    if (!isFlipped) {
      return null
    }

    return (
      <div css={style.contactContainer}>
        {
          CONTACT_INFO.map(info => (
            <a
              key={`contact-${info.key}`}
              css={style.contact({ color: info.color, theme })}
              href={info.href}
              onClick={ev => ev.stopPropagation()}
              target='_blank'
              rel='nofollow noreferrer'
            >
              <info.Icon />
              <span>{info.value}</span>
            </a>
          ))
        }
      </div>
    )
  }

  const Card = (props) => (
    <animated.div onClick={() => set(state => !state)} css={style.cardContainer} {...props}>
      <animated.div css={style.cardBack({ theme })} style={{ opacity: opacity.interpolate(op => 1 - op), transform }}>
        <CardBackContent />
      </animated.div>
      <animated.div css={style.cardFront({ theme })} style={{ opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
        <CardFrontContent />
      </animated.div>
    </animated.div>
  )

  const transitions = useTransition(true, null, {
    from: { transform: 'translate(100rem, 0rem) rotate(360deg)' },
    enter: { transform: 'translate(-6rem, 10rem) rotate(10deg)' },
    leave: { transform: 'translate(-100vw, 0rem)' },
  })

  return transitions.map(({ key, props }) => <Card key={key} style={props} {...extra} />)
}

export default CardComponent
