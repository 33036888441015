import { css } from '@emotion/core'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const container = css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-height: 30rem;
    max-width: 30rem;

    > object {
        position: absolute;
    }

    ${mqMax.md} {
        height: 40%;
    }
`
