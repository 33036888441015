import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { zIndexes } from '~/styles/constants'
import { transparentize } from 'polished'

export const overlay = ({ isActive, isOverlayVisible, transitionTime }) => css`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${transparentize(0.05, colors.black)};
    z-index: ${zIndexes.modalOverlay};
    overflow: hidden;
    opacity: 0;
    transition: ${transitionTime}ms;

    ${isActive && 'opacity: 1'};
    ${!isOverlayVisible && 'display: none'};
`

export const modal = css`
    background: ${colors.white};
    min-height: 10rem;
    min-width: 10rem;
    width: 80%;
    /* height: 50%; */
    border-radius: 0.8rem;
    padding: 2rem;
`