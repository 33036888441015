import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTransition, animated } from 'react-spring'

import * as style from './styles'

const OVERLAY_TRANSITION = 1000

/**
 * <Describe the Modal component here>
 *
 * @component
 * @usage `import Modal from '~/components/Modal'`
 * @example
 * <Add an example of how Modal is used here>
 */
const Modal = ({ children, isActive, onClose, ...extra }) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false)
  const transitions = useTransition(isActive, null, {
    from: { position: 'absolute', transform: 'translateY(100vh)' },
    enter: { transform: 'translateY(0)' },
    leave: { transform: 'translateY(-100vh)' },
  })

  useEffect(() => {
    if (isActive && !isOverlayVisible) {
      setOverlayVisible(isActive)
    } else if (!isActive && isOverlayVisible) {
      setTimeout(() => {
        setOverlayVisible(isActive)
      }, OVERLAY_TRANSITION)
    }

  }, [isActive])

  return (
    <main
      css={style.overlay({ isActive, isOverlayVisible, transitionTime: OVERLAY_TRANSITION })}
      onClick={onClose}
    >
      {
        transitions.map(({ item, key, props }) =>
          !item
            ? null
            : (
              <animated.span
                key={key}
                css={style.modal}
                style={props}
                onClick={ev => ev.stopPropagation()}
                {...extra}
              >
                {children}
              </animated.span>
            )
        )
      }
    </main>
  )
}

Modal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
}

Modal.defaultProps = {
  isActive: false,
  onClose: () => {},
}

export default Modal
