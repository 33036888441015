import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionSkills = ({ theme }) => css`
  background: ${colors.theme[theme].backgroundColor};
`

export const skillsContainer = css`
  position: relative;
  height: 30rem;
  max-width: 100rem;
  margin: 0 auto;
  overflow-x: hidden;

  ${mqMax.md} {
    height: 20rem;
  }
`

export const skillContainer = css`
  position: absolute;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(2, 45, 79, 0.1);
  background: ${colors.white};
  padding: 0.5rem;
  height: 4rem;
  width: 4.5rem;
  transform: matrix(0.97029, 0.34192, -0.84486, 0.9425, 0, 0);
  transition: 0.3s;

  &:hover {
    margin-top: -1rem;
    filter: drop-shadow(1rem 1rem 0.4rem ${colors.gray});
    z-index: 1;
  }

  ${mqMax.md} {
    height: 2rem;
    width: 2.5rem;
  }
`

export const skillImage = css`
  vertical-align: middle;
  height: auto;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

export const chartContainer = css`
  max-height: 50rem;
  max-width: 50rem;
  margin: 0 auto;
`