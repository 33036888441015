const DEFAULT_COLORS = {
  black: '#000000',
  gray: '#ecf0f1',
  lightGray: '#888',
  darkGray: '#1b1b1b', // todo: vs grayDark?
  red: '#e74c3c',
  blue: '#3498db',
  white: '#FFF',
  offWhite: '#f6f4df',
  green: '#2ecc71',
  orange: '#e67e22',
  purple: '#9b59b6',
  yellow: '#f1c40f',
  turquoise: '#1abc9c',
  midnightBlue: '#130f40',
}

const MISC_COLORS = {
  toolbox: '#a52a2a',
  brain: '#f59ead',
  helmet: '#ffc023',
  chatBubbles: {
    outgoing: {
      background: {
        from: '#00a3ea',
        to: '#0085D1',
      },
      text: DEFAULT_COLORS.white,
    },
    incoming: {
      background: {
        from: '#EEE',
        to: '#dcdcdc',
      },
      text: DEFAULT_COLORS.black,
    },
  },
  chatBackground: '#8080801a',
}

const BRAND_COLORS = {
  linkedin: '#2867B2',
  beatport: '#03c372',
  divz: '#02A3DD',
}

const gradients = {
  sunrise: {
    start: '#ffaf00',
    end: '#f3051f',
  },
  ocean: {
    start: '#001af6',
    end: '#00f5ff',
  },
}

const DARK_THEME = {
  titleColor: '#e8f7ff',
  backgroundColor: '#1d1d1d',
  backgroundColorAlt: DEFAULT_COLORS.black, // #03010e
  textColor: DEFAULT_COLORS.white,
  textColorAlt: DEFAULT_COLORS.white,
}

const LIGHT_THEME = {
  titleColor: DEFAULT_COLORS.black,
  backgroundColor: DEFAULT_COLORS.white,
  backgroundColorAlt: '#e8f7ff',
  textColor: '#666',
  textColorAlt: '#666',
}

export default {
  ...DEFAULT_COLORS,
  ...MISC_COLORS,
  ...BRAND_COLORS,
  theme: {
    dark: DARK_THEME,
    light: LIGHT_THEME,
  },
  gradients,
}
