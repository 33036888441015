import React from 'react'

import RevealFade from '~/components/RevealFade'
import Animation from '~/components/Animation'
import { BiPlusMedical } from 'react-icons/bi'

import * as style from './styles'

const AnimationWithLabel = ({ animation, label }) => (
  <div css={style.animationContainer}>
    <Animation name={animation} css={style.animation} />
    <span>{label}</span>
  </div>
)

const FooterSection = ({ theme }) => {
  const isDarkTheme = theme === 'dark'

  return (
    <section css={style.sectionFooter({ theme })}>
      Made with
      <div css={style.content}>
        <RevealFade>
          <AnimationWithLabel animation='heartBeat' label='Love' />
          <BiPlusMedical />
        </RevealFade>
        <RevealFade delay={500}>
          <AnimationWithLabel animation='musicDancing' label='Music' />
          <BiPlusMedical />
        </RevealFade>
        <RevealFade delay={750}>
          <AnimationWithLabel animation={isDarkTheme ? 'burritoMunchingDark' : 'burritoMunching'} label='Burritos' />
        </RevealFade>
      </div>
    </section>
  )
}

export default FooterSection
