import { css, keyframes } from '@emotion/core'
import colors from '~/styles/colors'

const animationSpin = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

export const spinner = ({ isActive, theme }) => css`
  color: ${colors.theme[theme].textColor};
  animation-name: ${animationSpin};
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
  opacity: 0;
  transition: 0.5s;

  ${isActive && `
    opacity: 1;
  `}
`
