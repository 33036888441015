import React, { useState, useEffect } from 'react'

import { isMobile } from 'react-device-detect'

import Modal from '~/components/Modal'
import Avatar from '~/components/Avatar'
import { FaChrome } from 'react-icons/fa'
import { FcCheckmark, FcCancel } from 'react-icons/fc'
import Button from '~/components/Button'

import ScreenMobile from '~/public/static/images/misc/screen-mobile.svg'
import ScreenDesktop from '~/public/static/images/misc/screen-desktop.svg'

import * as style from './styles'

const ANCHOR_CHROME = {
  href: 'https://www.google.com/chrome/',
  target: '_blank',
  rel: 'nofollow',
}

/**
 * <Describe the MobileWarningModal component here>
 *
 * @component
 * @usage `import MobileWarningModal from '~/components/MobileWarningModal'`
 * @example
 * <Add an example of how MobileWarningModal is used here>
 */
const MobileWarningModal = () => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(isMobile)
  }, [])

  const onClose = () => { setIsActive(false) }

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      css={style.modal}
    >
      <h2 css={style.title}>Woah! Slow down there, champ!</h2>

      <span css={style.body}>
          Looks like you&apos;re using your phone to browse my website.
          For a better experience, try browsing this website on your computer!
          I also recommend using <a {...ANCHOR_CHROME}><FaChrome /> Chrome Browser!</a>
      </span>

      <div css={style.screensContainer}>
        <div>
          <div css={style.screenWrapper}>
            <ScreenMobile css={style.imageMobile} />
          </div>
          <FcCancel css={style.iconDeny} />
        </div>
        <div>
          <div css={style.screenWrapper}>
            <ScreenDesktop css={style.imageDesktop} />
          </div>
          <FcCheckmark css={style.iconApprove} />
        </div>
      </div>

      <Button color='red' onClick={onClose}>
          Whatever
      </Button>

      <div css={style.avatarContainer}>
        <Avatar mood='thinking' avatarStyle='Transparent' />
      </div>
    </Modal>
  )
}

export default MobileWarningModal
