import React from 'react'
import PropTypes from 'prop-types'

import WavesAnimated from './WavesAnimated'

import * as style from './styles'


/**
 * <Describe the SectionDivider component here>
 *
 * @component
 * @usage `import SectionDivider from '~/components/SectionDivider'`
 * @example
 * <Add an example of how SectionDivider is used here>
 */
const SectionDivider = ({ theme, ...extra }) => {
  return (
    <div css={style.sectionDivider} {...extra}>
      <WavesAnimated theme={theme} />
    </div>
  )
}

SectionDivider.propTypes = {
  theme: PropTypes.string,
}

export default SectionDivider
