import React from 'react'

import { useSprings, animated } from 'react-spring'
import SectionTitle from '~/components/SectionTitle'
import SectionSubtitle from '~/components/SectionSubtitle'
import Radar from '~/components/Charts/Radar'
import RevealFade from '~/components/RevealFade'

import skillsFixture from '~/fixtures/skills'

import * as style from './styles'

const { technologies, skillLevel } = skillsFixture

const Title = () => (
  <SectionTitle animation='arrowOnTarget' blob={3}>
    Skills and Knowledge
  </SectionTitle>
)

const SkillIcons = () => {
  const springs = useSprings(technologies.length, technologies)

  return (
    <div css={style.skillsContainer}>
      {
        springs.map((spring, index) => {
          const { key, Component } = technologies[index]

          return (
            <animated.div
              key={`skill-container_${key}`}
              css={style.skillContainer}
              style={spring}
            >
              <Component css={style.skillImage} />
            </animated.div>
          )
        })
      }
    </div>
  )
}

const SkillsSection = ({ theme }) => {
  return (
    <section css={style.sectionSkills({ theme })}>
      <Title />
      <SectionSubtitle>Constantly Expanding</SectionSubtitle>
      <SkillIcons />
      <SectionSubtitle>
        Constantly Learning
        <br/>
        <small>(These skills and much more!)</small>
      </SectionSubtitle>
      <RevealFade>
        <div css={style.chartContainer}>
          <Radar {...skillLevel} />
        </div>
      </RevealFade>
    </section>
  )
}

export default SkillsSection
