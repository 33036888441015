import React from 'react'

import SectionTitle from '~/components/SectionTitle'
import SectionSubtitle from '~/components/SectionSubtitle'
import Card from '~/components/Card'

import * as style from './styles'

const Title = () => (
  <SectionTitle animation='mailInMailbox' blob={8}>
    Get In Touch
  </SectionTitle>
)

const ContactSection = ({ theme }) => {
  return (
    <section css={style.sectionContact({ theme })}>
      <Title />
      <div css={style.cardContainer}>
        <SectionSubtitle>Here&apos;s My Card</SectionSubtitle>
        <Card theme={theme} css={style.card} />
      </div>
    </section>
  )
}

export default ContactSection
