import { css } from '@emotion/core'
import { mqMax } from '~/styles/mixins/mediaQueries'
import colors from '~/styles/colors'

export const title = ({ theme }) => css`
  position: relative;
  font-size: 3.75rem;
  color: ${colors.theme[theme].titleColor};

  ${mqMax.md} {
    font-size: 2.75rem;
  }
`

export const visual = css`
  position: inherit;
  height: 10rem;
  z-index: 1;
`

export const titleContent = css`
  position: inherit;
  z-index: 1;
`

export const blob = css`
  position: absolute;
  max-width: 15rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0rem;
  z-index: 0;
  opacity: 0.25;

  ${mqMax.md} {
    max-width: 10rem;
    bottom: 3rem;
  }
`