const ABOUT = {
  description: `
    You know my name, but maybe you don't know who I am. If you're here, chances are you were given a link to this page. So welcome to a page on the interwebs that I can call my own!
    <br />
    <br />
    I'm a 20-something year old developer who's on an adventure to become an expert in my field. Ah but you might be asking "What is your field, Joseph?". Well reader <strike>who probably didn't ask that question</strike>, I'll tell you; I'm into a little bit of everything. My aim is to become a proficient full-stack developer who does a <strike>little</strike> lot of everything! I'll be sure to keep this page updated throughout my career. But for now, enjoy the rest of the that webpage I wrote at 4 in the morning on a Friday night!
  `,
  strengths: [
    { title: 'Responsible', animation: 'wateringPlant', body: 'If given a position, I\'ll do my best to fill the shoes I\'ve been given! Rest assured that my responsibilities just don\'t stop at the duties given to me!' },
    { title: 'Hard Working', animation: 'hammerAnvil', body: 'You bet your bottom dollar I\'m going to work hard on that thing I was supposed to do 3 days ago. If a particular job needs an all-nighter, just order me some pizza and let me at it!' },
    { title: 'Dependable', animation: 'fistBump', body: 'Can I patch that bug before deployment? Yep. Want me to walk your dog while you\'re out of town? Sure thing. Need help disposing of a body after a drunken black out? Give me a call. You can count on me!' },
    { title: 'Hungry', animation: 'floatingPizza', body: 'Not just for food, but also for knowledge. But mostly food. You\'ll often find me asking questions about things I have no business asking questions about (in a good way?)... While I snatch a slice of your pizza.' },
  ],
  weaknesses: [
    { title: 'Perfectionist', animation: 'shiningDiamond', body: 'What can I say? I am what I eat... Or something like that. But I can\'t stand to see anything that has been made in standards lower than what I have set for myself. This could result in people (you) hating me. A lot. I may spend more time on a certain project, or extend deadlines, to meet my own expectations.' },
  ],
}

export default ABOUT
