import { css } from '@emotion/core'
import colors from '~/styles/colors'

export const layout = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.theme[theme].textColor};
`

export const main = ({ theme }) => css`
  height: 100%;
  background: ${colors.theme[theme].backgroundColorAlt};

  section {
    min-height: calc(100vh - 4rem); // 100vh - padding bottom + padding top
    padding: 2rem 10%;
    overflow: hidden;
  }
`
