import { css, keyframes } from '@emotion/core'

const ANIMATION_DURATION = 20
const FLOAT_POS_MAX = Math.floor(Math.random() * 9) + 5 // todo: From helper?
const FLOAT_POS_MIN = Math.floor(Math.random() * 9) + 5

const float = keyframes`
 0%, 100% {
    transform: translateY(0);
  }
  10%, 30%, 50%, 70% {
    transform: translateY(-${FLOAT_POS_MAX}px);
  }
  20%, 40%, 60% {
    transform: translateY(${FLOAT_POS_MAX}px);
  }
  80% {
    transform: translateY(${FLOAT_POS_MIN}px);
  }
  90% {
    transform: translateY(-${FLOAT_POS_MIN}px);
  }
`

export default duration => css`
  animation: ${float} ${duration || ANIMATION_DURATION}s ease-in-out infinite both;
  ${'' /* animation-delay: 0.5s; */}
`
