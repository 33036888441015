import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

const buttonColors = (theme) => {
  const THEMES = {
    light: css`
      background: ${colors.theme.dark.backgroundColorAlt};
      color: ${colors.theme.dark.textColor};

      &:hover {
        background: ${colors.theme.dark.backgroundColor};
      }
    `,
    dark: css`
      background: ${colors.theme.light.backgroundColorAlt};
      color: ${colors.yellow};

      &:hover {
        background: ${colors.theme.light.backgroundColor};
      }
    `,
  }

  return THEMES[theme]
}

export const buttonTheme = ({ theme }) => css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  outline: none;
  border: none;
  border-radius: 100%;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  ${buttonColors(theme)};
  z-index: 10; // todo: Organize zIndexes
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  ${mqMax.md} {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
  }
`
