import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionAbout = ({ theme }) => css`
  background: ${colors.theme[theme].backgroundColor};
`

export const aboutDescription = css`
  margin-bottom: 2rem;

  ${mqMax.md} {
    text-align: center;
  }
`

export const qualitiesContainer = css`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
`

export const quality = css`
  text-align: center;
  width: 100%;
  max-width: 20rem;
  margin: 0 5rem;
  margin-bottom: 2rem;

  ${mqMax.md} {
    margin: 0 auto;
  }
`

export const qualityVisual = css`
  width: 70%;
`

export const qualityTitle = css`
  margin-top: 0.5rem;
`

export const qualityBody = css``