import React from 'react'
import PropTypes from 'prop-types'

import Animation from '~/components/Animation'

import * as style from './styles'

/**
 * <Describe the Card component here>
 *
 * @component
 * @usage `import Card from '~/components/Card'`
 * @example
 * <Add an example of how Card is used here>
 */
const BlobsSignature = ({ theme, ...extra }) => {
  const isDarkTheme = theme === 'dark'

  return (
    <div css={style.container} {...extra}>
      <Animation name='morphingBlobs' />
      <Animation name={isDarkTheme ? 'signatureWhite' : 'signature'} />
    </div>
  )
}

BlobsSignature.propTypes = {
  theme: PropTypes.string,
}

BlobsSignature.defaultProps = {
}

export default BlobsSignature
