import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'

import preloader from '~/utils/preLoader'

import StandardLayout from '~/layouts/Standard'
import AboutSection from '~/sections/About'
import Cover from '~/sections/Cover'
import NewIntro from '~/sections/Intro'
import NewHobbies from '~/sections/Hobbies'
import NewContact from '~/sections/Contact'
import NewSkills from '~/sections/Skills'
import NewExperience from '~/sections/Experience'
import NewFooter from '~/sections/Footer'

import LazyLoader from '~/components/LazyLoader'
import ThemeButton from '~/components/ThemeButton'
import VersionNumber from '~/components/VersionNumber'
import MobileWarningModal from '~/components/MobileWarningModal'
import isDevelopment from '~/utils/isDevelopment'
import EasterEgg from '~/components/EasterEgg'

const SECTIONS = [
  NewIntro,
  AboutSection,
  NewHobbies,
  NewSkills,
  NewExperience,
  NewContact,
  NewFooter,
]

const IndexPage = ({ rootStore }) => {
  const [hasPreloaded, setHasPreloaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { theme } = rootStore.stateStore
  const commonProps = {
    theme,
  }

  const preloadContent = async () => {
    await preloader()
    setHasPreloaded(true)
  }

  useEffect(() => { preloadContent() }, [])

  const AllSections = () => SECTIONS.map((Section, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <LazyLoader key={`section_index-${index}`}>
      <Section {...commonProps} />
    </LazyLoader>
  ))

  return (
    <>
      <ThemeButton />
      {
        isLoading && !isDevelopment
          ? (
            <Cover
              hasLoaded={hasPreloaded}
              onFinish={() => setIsLoading(false)}
              {...commonProps}
            />
          )
          : (
            <AllSections />
          )
      }
      <VersionNumber />
      <EasterEgg />
      <MobileWarningModal />
    </>
  )
}

export default StandardLayout(observer(IndexPage))
