import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { transparentize } from 'polished'
import { mqMin, mqMax } from '~/styles/mixins/mediaQueries'

export const cardContainer = css`
    display: flex;
    justify-content: center;
    filter: drop-shadow(0.3rem 0.3rem 0.3rem ${transparentize(0.25, colors.black)});
    height: 1rem;
    width: 1rem;
`

const card = theme => css`
    position: absolute;
    width: 61.25vw;
    height: 35vw;
    max-width: 40rem;
    max-height: 22.85rem;
    background: ${colors.theme[theme].backgroundColor};
    cursor: pointer;
    will-change: transform, opacity;

    ${mqMax.md} {
        width: 81.25vw;
        height: 45vw;
    }
`

export const cardFront = ({ theme }) => css`
    ${card(theme)};
`

export const cardBack = ({ theme }) => css`
    ${card(theme)};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const logoContainer = css`
    width: 65%;
    height: 65%;
`

export const contactContainer = css`
    font-size: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 20% 0 10%;
`

export const contact = ({ color, theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: ${colors.theme[theme].textColor};
    text-decoration: none;
    transition: 0.2s;

    > span {
        font-size: 4vw;

        ${mqMin.xl} {
            font-size: 1.5rem;
        }
    }

    > svg {
        margin-right: 5%;
        font-size: 7vw;

        ${mqMin.xl} {
            font-size: 3.5rem;
        }
    }

    &:hover {
        color: ${colors[color]}
    }
`