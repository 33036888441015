import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Card component here>
 *
 * @component
 * @usage `import Card from '~/components/Card'`
 * @example
 * <Add an example of how Card is used here>
 */
const Animation = ({ name, ...extra }) => (
  <object
    type='image/svg+xml'
    data={`/static/animations/${name}.svg`}
    css={style.animationObject}
    {...extra}
  />
)

Animation.propTypes = {
  name: PropTypes.string,
}

Animation.defaultProps = {
  name: '',
}

export default Animation
