export const BREAKPOINT_SIZES = {
  SCREEN_EXTRA_SMALL: {
    size: 0,
    code: 'xs',
  },
  SCREEN_SMALL: {
    size: 568,
    code: 'sm',
  },
  SCREEN_MEDIUM: {
    size: 768,
    code: 'md',
  },
  SCREEN_LARGE: {
    size: 1024,
    code: 'lg',
  },
  SCREEN_EXTRA_LARGE: {
    size: 1150,
    code: 'xl',
  },
  SCREEN_EXTRA_EXTRA_LARGE: {
    size: 1681,
    code: 'xxl',
  },
}

export const mqMin = Object.keys(BREAKPOINT_SIZES).reduce((acc, key) => {
  const currentBreakpoint = BREAKPOINT_SIZES[key]
  acc[currentBreakpoint.code] = `@media screen and (min-width: ${currentBreakpoint.size}px)`
  return acc
}, {})

export const mqMax = Object.keys(BREAKPOINT_SIZES).reduce((acc, key) => {
  const currentBreakpoint = BREAKPOINT_SIZES[key]
  acc[currentBreakpoint.code] = `@media screen and (max-width: ${currentBreakpoint.size}px)`
  return acc
}, {})
