import React, { useState, useEffect } from 'react'

import Animation from '~/components/Animation'
import Spinner from '~/components/Spinner'

import * as style from './styles'

const HIDE_AFTER = 3500 // in ms
const TRANS_TIME = 1000 // in ms

const CoverSection = ({ theme, hasLoaded, onFinish }) => {
  const [shouldHideCover, setShouldHideCover] = useState(false)

  useEffect(() => {
    if (!shouldHideCover && hasLoaded) {
      setTimeout(() => {
        setShouldHideCover(true)
        setTimeout(() => {
          onFinish()
        }, TRANS_TIME)
      }, HIDE_AFTER)
    }
  }, [hasLoaded])

  return (
    <section css={style.sectionCover({ theme, shouldHideCover, transitionTime: TRANS_TIME })}>
      <div css={style.coverElements}>
        <Animation name={hasLoaded ? 'serverReady' : 'serverLoading'} css={style.animationServer} />
        {/* <TerminalWindow
          title='index.js'
          code={websiteFixture}
          css={style.terminal}
          onFinish={() => console.log('DONE')}
        /> */}
        <Spinner isActive={!hasLoaded} css={style.spinner} />
      </div>
    </section>
  )
}

export default CoverSection
