import React from 'react'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'

import { Radar } from 'react-chartjs-2'

import colors from '~/styles/colors'

const OPTIONS = {
  legend: {
    display: false,
  },
  scale: {
    ticks: {
      maxTicksLimit: 3,
    },
    gridLines: {
      color: colors.lightGray,
    },
    angleLines: {
      color: colors.lightGray,
    },
  },
}

const parseData = ({ data, labels }, canvas) => {
  const ctx = canvas.getContext('2d')
  const gradientBackground = ctx.createLinearGradient(0, 0, 0, 400)
  gradientBackground.addColorStop(0, transparentize(0.65, colors.gradients.ocean.end))
  gradientBackground.addColorStop(1, transparentize(0.65, colors.gradients.ocean.start))

  const gradientOutline = ctx.createLinearGradient(0, 0, 0, 400)
  gradientOutline.addColorStop(0, colors.gradients.ocean.end)
  gradientOutline.addColorStop(1, colors.gradients.ocean.start)

  return {
    labels,
    datasets: [
      {
        label: 'Skill Level',
        backgroundColor: gradientBackground,
        borderColor: gradientOutline,
        pointBackgroundColor: gradientBackground,
        pointBorderColor: gradientOutline,
        // backgroundColor: 'rgba(255,99,132,0.2)',
        // borderColor: 'rgba(255,99,132,1)',
        // pointBackgroundColor: 'rgba(255,99,132,1)',
        // pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        lineTension: 0.4,
        data,
      },
    ],
  }
}

/**
 * <Describe the Radar component here>
 *
 * @component
 * @usage `import Radar from '~/components/Radar'`
 * @example
 * <Add an example of how Radar is used here>
 */
const RadarComponent = (props) => {
  return (
    <Radar
      data={canvas => parseData(props, canvas)}
      options={OPTIONS}
    />
  )
}

RadarComponent.propTypes = {
  labels: PropTypes.array,
  data: PropTypes.array,
}

RadarComponent.defaultProps = {
  labels: [],
  data: [],
}

export default RadarComponent
