import { css } from '@emotion/core'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const title = css`
  font-size: 2.5rem;

  ${mqMax.sm} {
      font-size: 1.5rem
  }
`

export const body = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.2rem;

  ${mqMax.sm} {
    font-size: 1rem
  }
`

export const animationEgg = css`
  max-height: 13rem;

  ${mqMax.md} {
    max-height: 8rem;
  }
`