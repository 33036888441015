import { css } from '@emotion/core'
import colors from '~/styles/colors'

export const subtitle = ({ theme }) => css`
  font-size: 1.75rem;
  color: ${colors.theme[theme].titleColor};

  > small {
    font-size: 1rem;
  }
`
