import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Button component here>
 *
 * @component
 * @usage `import Button from '~/components/Button'`
 * @example
 * <Add an example of how Button is used here>
 */
const Button = ({ children, color, ...extra }) => {
  return (
    <button css={style.button(color)} {...extra}>
      {children}
    </button>
  )
}

Button.propTypes = {
  color: PropTypes.string,
}

Button.defaultProps = {
  color: 'white',
}

export default Button
