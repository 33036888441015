import React from 'react'

import LazyLoad from 'react-lazyload'

/**
 * <Describe the LazyLoader component here>
 *
 * @component
 * @usage `import LazyLoader from '~/components/LazyLoader'`
 * @example
 * <Add an example of how LazyLoader is used here>
 */
const LazyLoader = (props) => (
  <LazyLoad height={'100vh'} {...props} />
)

export default LazyLoader
