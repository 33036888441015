import { random as loRandom } from 'lodash'

// todo: rename helper

export const randomBoxCoordinates = ({
  lowerBounds = { x: -100, y: -100 },
  upperBounds = { x: 100, y: 100 },
}) => {
  const AOs = {
    top: { x: Math.floor(Math.random() * upperBounds.x) + lowerBounds.x, y: upperBounds.y },
    bottom: { x: Math.floor(Math.random() * upperBounds.x) + lowerBounds.x, y: lowerBounds.y },
    left: { x: lowerBounds.x, y: Math.floor(Math.random() * upperBounds.y) + lowerBounds.y },
    right: { x: upperBounds.x, y: Math.floor(Math.random() * upperBounds.y) + lowerBounds.y },
  }

  const optionValues = Object.values(AOs)

  return optionValues[Math.floor(Math.random() * optionValues.length)]
}

export const randomLinearCoordinates = ({
  lowerBounds = { x: -100, y: -100 },
  upperBounds = { x: 100, y: 100 },
}) => {
  return {
    x: loRandom(lowerBounds.x, upperBounds.x),
    y: loRandom(lowerBounds.y, upperBounds.y),
  }
}

export const random = (min = 0, max = 100) => {
  return loRandom(min, max)
}
