import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import Animation from '~/components/Animation'
import RevealFade from '~/components/RevealFade'
import { random } from '~/helpers/coordinatesHelper'
import { isNil } from 'lodash'

import Blob1 from '~/public/static/images/blobs/blob-1.svg'
import Blob2 from '~/public/static/images/blobs/blob-2.svg'
import Blob3 from '~/public/static/images/blobs/blob-3.svg'
import Blob4 from '~/public/static/images/blobs/blob-4.svg'
import Blob5 from '~/public/static/images/blobs/blob-5.svg'
import Blob6 from '~/public/static/images/blobs/blob-6.svg'
import Blob7 from '~/public/static/images/blobs/blob-7.svg'
import Blob8 from '~/public/static/images/blobs/blob-8.svg'
import Blob9 from '~/public/static/images/blobs/blob-9.svg'

import * as style from './styles'

const BLOBS = [
  Blob1,
  Blob2,
  Blob3,
  Blob4,
  Blob5,
  Blob6,
  Blob7,
  Blob8,
  Blob9,
]

/**
 * <Describe the SectionTitle component here>
 *
 * @component
 * @usage `import SectionTitle from '~/components/SectionTitle'`
 * @example
 * <Add an example of how SectionTitle is used here>
 */
const SectionTitle = ({ rootStore, animation, children, blob, ...extra }) => {
  const blobIndex = isNil(blob) ? random(0, BLOBS.length - 1) : blob - 1
  const Blob = BLOBS[blobIndex]
  const { theme } = rootStore.stateStore

  return (
    <RevealFade delay={50}>
      <h2 css={style.title({ theme })} {...extra}>
        <Animation name={animation} css={style.visual} />
        <span css={style.titleContent}>
          {children}
        </span>
        <Blob css={style.blob} />
      </h2>
    </RevealFade>
  )
}

SectionTitle.propTypes = {
  animation: PropTypes.string,
  blob: PropTypes.oneOf(Array(BLOBS.length).keys()),
}

SectionTitle.defaultProps = {
  animation: '',
}

export default inject('rootStore')(observer(SectionTitle))
