import React from 'react'
import { inject, observer } from 'mobx-react'

import { RiMoonFoggyLine, RiSunFoggyLine } from 'react-icons/ri'

import * as style from './styles'

/**
 * <Describe the ThemeButton component here>
 *
 * @component
 * @usage `import ThemeButton from '~/components/ThemeButton'`
 * @example
 * <Add an example of how Button is used here>
 */
const ThemeButton = ({ rootStore }) => {
  const { theme } = rootStore.stateStore
  const isDarkTheme = theme === 'dark'

  return (
    <button
      css={style.buttonTheme({ theme })}
      onClick={() => rootStore.stateStore.switchTheme()}
    >
      {
        isDarkTheme
          ? <RiSunFoggyLine />
          : <RiMoonFoggyLine />
      }
    </button>
  )
}

export default inject('rootStore')(observer(ThemeButton))
