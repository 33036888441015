import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import * as style from './styles.js'

/**
 * StandardLayout
 *
 * @component
 * @usage `import StandardLayout from '~/layouts/Standard'`
 * @example
 * // Export the page using the HOC:
 * export default StandardLayout(MyPage)
 */
const StandardLayoutHOC = (Child) => {
  const StandardLayoutComponent = (props) => {
    const { rootStore } = props
    const { theme } = rootStore.stateStore

    return (
      <section css={style.layout({ theme })}>
        <main css={style.main({ theme })}>
          <Child {...props} />
        </main>
      </section>
    )
  }

  StandardLayoutComponent.getInitialProps = async (ctx) => {
    const childProps = Child.getInitialProps
      ? await Child.getInitialProps(ctx)
      : {}

    return {
      ...childProps,
    }
  }

  StandardLayoutComponent.propTypes = {
    currentRoute: PropTypes.object,
  }

  StandardLayoutComponent.defaultProps = {
    currentRoute: {},
  }

  return inject('rootStore')(
    observer(
      StandardLayoutComponent
    )
  )
}

export default StandardLayoutHOC
