import { css, keyframes } from '@emotion/core'


const appearIn = keyframes`
 0% {
    opacity: 0;
    transform: scale(3) rotate(45deg);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`

export default () => css`
  animation: ${appearIn} 5s ease-in-out infinite both;
`
