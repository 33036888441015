import React from 'react'

import SectionTitle from '~/components/SectionTitle'
import Animation from '~/components/Animation'
import RevealFade from '~/components/RevealFade'
import SectionSubtitle from '~/components/SectionSubtitle'

import aboutFixture from '~/fixtures/about'

import * as style from './styles'

const AboutDescription = () => (
  <p
    css={style.aboutDescription}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: aboutFixture.description }}
  />
)

const Quality = ({ title, animation, body }) => (
  <div css={style.quality}>
    <Animation name={animation} css={style.qualityVisual} />
    <h4 css={style.qualityTitle}>{title}</h4>
    <p css={style.qualityBody}>{body}</p>
  </div>
)

const AboutStrengths = () => (
  <div css={style.qualitiesContainer}>
    {
      aboutFixture.strengths.map(strength => (
        <RevealFade key={`about_strength-${strength.title}`}>
          <Quality {...strength} />
        </RevealFade>
      ))
    }
  </div>
)

const AboutWeaknesses = () => (
  <div css={style.qualitiesContainer}>
    {
      aboutFixture.weaknesses.map(weakness => (
        <RevealFade key={`about_weakness-${weakness.title}`}>
          <Quality {...weakness} />
        </RevealFade>
      ))
    }
  </div>
)

const Title = () => (
  <SectionTitle animation='lightBulb' blob={7}>
    About Me
  </SectionTitle>
)

const AboutSection = ({ theme }) => {
  return (
    <section css={style.sectionAbout({ theme })}>
      <Title />
      <RevealFade>
        <AboutDescription />
      </RevealFade>
      <RevealFade>
        <SectionSubtitle>Strengths</SectionSubtitle>
      </RevealFade>
      <AboutStrengths />
      <RevealFade>
        <SectionSubtitle>Weaknesses</SectionSubtitle>
      </RevealFade>
      <AboutWeaknesses />
    </section>
  )
}

export default AboutSection
