import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionCover = ({ theme, shouldHideCover, transitionTime = 1000 }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.theme[theme].backgroundColorAlt};
  transition: ${transitionTime}ms;

  ${shouldHideCover && `
    transform: translateY(-100vh);
  `}
`

export const coverElements = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${mqMax.md} {
    /* height: 80vh; */
    justify-content: space-evenly;
    flex-direction: column;
  }
`

export const animationServer = css`
  max-width: 30rem;

  ${mqMax.md} {
    max-width: unset;
  }
`

export const terminal = css`
  width: 100%;
  max-width: 32rem;
`

export const spinner = css`
  font-size: 4rem;
  position: absolute;
  bottom: 10%;
`
