import React from 'react'
import Bounce from 'react-reveal/Fade'

/**
 * <Describe the RevealFade component here>
 *
 * @component
 * @usage `import RevealFade from '~/components/RevealFade'`
 * @example
 * <Add an example of how RevealFade is used here>
 */
const RevealFade = ({ children, ...props }) => (
  <Bounce delay={250} top {...props}>
    {children}
  </Bounce>
)

export default RevealFade
