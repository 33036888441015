import React from 'react'

import colors from '~/styles/colors'

const WavesAnimated = ({ theme }) => (
  <svg
    className='editorial'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 24 150 28 '
    preserveAspectRatio='none'
  >
    <defs>
      <path
        id='gentle-wave'
        // d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
        d='M-160 44c30 0 58-18 88 -18s58 18 88 18s58-18 88 -18s58 18 88 18v-76h-352z'
      />
    </defs>
    <g className='parallax1'>
      <use xlinkHref='#gentle-wave' x='50' y='3' fill={colors.gradients.sunrise.start} />
    </g>
    <g className='parallax2'>
      <use xlinkHref='#gentle-wave' x='50' y='0' fill={colors.gradients.sunrise.start} />
    </g>
    <g className='parallax3'>
      <use xlinkHref='#gentle-wave' x='50' y='9' fill={colors.gradients.ocean.end} />
    </g>
    <g className='parallax4'>
      <use xlinkHref='#gentle-wave' x='50' y='6' fill={colors.theme[theme].backgroundColorAlt} />
    </g>
  </svg>
)

export default WavesAnimated
