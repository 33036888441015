import { css } from '@emotion/core'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

const zIndexes = {
  foreground: 1,
  background: 0,
}

export const sectionHobbies = ({ theme }) => css`
  position: relative;
  background: ${colors.theme[theme].backgroundColorAlt};
  
  > * {
    z-index: ${zIndexes.foreground};
  }
`

export const hobbyPicker = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 60rem;
  margin: 0 auto;
`

export const hobby = isSelected => css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  min-width: 9rem;
  flex: 1;
  font-size: 0.75rem;
  margin-bottom: 2rem;
  opacity: 0.25;
  z-index: inherit;
  cursor: pointer;
  transition: 0.2s;

  > img {
    height: 100%;
    max-height: 4rem;
    margin-bottom: 1rem;
  }

  ${mqMax.md} {
    min-width: unset;
  }

  ${!isSelected && `
    &:hover {
      opacity: 0.5;
      transform: scale(1.2) !important;
      filter: drop-shadow(0rem 0rem 0.5rem ${colors.gray});
    }
  `};

  ${isSelected && `
    cursor: default;
    opacity: 1;
  `};
`

export const hobbyVisual = css`
  max-width: 5rem;
  margin-bottom: 1rem;

  ${mqMax.md} {
    width: 75%;
  }
`

export const animationWrapper = css`
  margin-bottom: 2rem;
`

export const hobbyAnimation = css`
  max-height: 20rem;

  ${mqMax.md} {
    max-height: 15rem;
  }
`

export const descriptionContainer = css`
  position: relative;
  min-height: 12rem;
  height: auto;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;

  ${mqMax.md} {
    min-height: 29rem;
  }
`

export const description = css`
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
`

export const dynamicBackground = (backgroundColor) => css`
  position: absolute;
  width: 0rem;
  height: 0rem;
  border-radius: 100%;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndexes.background} !important;
  background: ${backgroundColor};
  transition: 0.75s;

  ${backgroundColor && `
    width: 150vw;
    height: 150vw;
  `};

  ${mqMax.md} {
    ${backgroundColor && `
      width: 200vh;
      height: 200vh;
    `};
  }
`
