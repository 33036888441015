import Computer from '~/public/static/images/hobbies/computer.svg'
import Drone from '~/public/static/images/hobbies/drone.svg'
import Game from '~/public/static/images/hobbies/game.svg'
import Map from '~/public/static/images/hobbies/map.svg'

const HOBBIES = [
  {
    label: 'Coding',
    Icon: Computer,
    backgroundColor: '',
    animation: 'coding',
    description: 'I do this. Every. Day. If I didn\'t like doing it, I would have gone crazy by now. But I\'m not. I\'ve been writing scripts and snipets of code since I was 13 years old. I love exploring different fields of coding, programming, and development.',
  },
  {
    label: 'Gaming',
    Icon: Game,
    backgroundColor: '',
    animation: 'gaming',
    description: 'One of my favorite passtimes. Whether I\'m raiding a medieval castle with friends on PC, playing mario party games with friends on my Switch, or shitting my pants while I play a horror VR game, I always thoroughly enjoy my active entertainment.',
  },
  {
    label: 'Flying',
    Icon: Drone,
    backgroundColor: 'skyblue',
    animation: 'drone',
    description: 'My love of technology extends to hardware. I have a huge fascination with autonomous robots; So I love taking my drone out for a spin. It\'s also awakned a love for photography in me. I love flying my drone over scenic views and getting the perfect shot. Well, as perfect as I can get it to be; I\'m still training.',
  },
  {
    label: 'Adventuring',
    Icon: Map,
    backgroundColor: '#2c4873',
    animation: 'camping',
    description: 'Going on adventures with my significant other has got to be my favorite activity. It could be as small and simple as going to the beach or camping outdoors, or as big and thrilling as traveling to a new country or driving across the country; An adventure is what you make it out to be.',
  },
]

export default HOBBIES
