import { css } from '@emotion/core'

export const versionNumber = css`
  position: fixed;
  bottom: 0rem;
  font-size: 0.75rem;
  padding: 0.5rem;
  z-index: 10;
  opacity: 0;
  cursor: cell;
  transition: 0.75s;

  &:hover {
    opacity: 1;
  }
`