import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import { CgSpinnerTwo } from 'react-icons/cg'

import * as style from './styles'

/**
 * <Describe the Spinner component here>
 *
 * @component
 * @usage `import Spinner from '~/components/Spinner'`
 * @example
 * <Add an example of how Spinner is used here>
 */
const Spinner = ({ rootStore, isActive, ...extra }) => {
  const { theme } = rootStore.stateStore

  return (
    <CgSpinnerTwo css={style.spinner({ isActive, theme })} {...extra} />
  )
}

Spinner.propTypes = {
  isActive: PropTypes.bool,
}

Spinner.defaultProps = {
  isActive: true,
}

export default inject('rootStore')(observer(Spinner))
