import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Avatar from 'avataaars'
import moods, { allMoods } from './moods'

const TALK_SPEED = 200

/**
 * <Describe the Avatar component here>
 *
 * @component
 * @usage `import Avatar from '~/components/Avatar'`
 * @example
 * <Add an example of how Avatar is used here>
 */
const AvatarComponent = ({ isTalking, mood, ...extra }) => {
  const [currentInterval, setCurrentInterval] = useState()
  const [isMouthOpen, setIsMouthOpen] = useState(false)

  const talk = () => {
    setIsMouthOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (isTalking) {
      setCurrentInterval(setInterval(() => {
        talk()
      }, TALK_SPEED))
    } else {
      clearInterval(currentInterval)
      setIsMouthOpen(false)
    }
  }, [isTalking])

  const avatarMood = isMouthOpen
    ? moods[`${mood}Talking`] || moods[mood]
    : moods[mood]

  return (
    <Avatar
      {...avatarMood}
      {...extra}
    />
  )
}

AvatarComponent.propTypes = {
  isTalking: PropTypes.bool,
  mood: PropTypes.oneOf(allMoods),
}

AvatarComponent.defaultProps = {
  isTalking: false,
  mood: 'happy',
}

export default AvatarComponent
