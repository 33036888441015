import React from 'react'

import { version } from '~/package.json'

import * as style from './styles'

/**
 * <Describe the VersionNumber component here>
 *
 * @component
 * @usage `import VersionNumber from '~/components/VersionNumber'`
 * @example
 * <Add an example of how VersionNumber is used here>
 */
const VersionNumber = () => {
  return (
    <div css={style.versionNumber}>
      {version}
    </div>
  )
}

VersionNumber.propTypes = {
}

VersionNumber.defaultProps = {
}

export default VersionNumber
