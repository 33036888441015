import React, { useState } from 'react'

import SectionTitle from '~/components/SectionTitle'
import { useTransition, useTrail, animated } from 'react-spring'
import Animation from '~/components/Animation'

import hobbiesFixture from '~/fixtures/hobbies'

import * as style from './styles'

const Title = () => (
  <SectionTitle animation='dressup' blob={6}>
    Hobbies
  </SectionTitle>
)

const HobbyPicker = ({ selectedExperience, onChange }) => {
  const trail = useTrail(hobbiesFixture.length, {
    config: { mass: 1, tension: 120, friction: 14 },
    from: {
      x: 100,
    },
    to: {
      x: 0,
    },
  })

  return (
    <div css={style.hobbyPicker}>
      {trail.map(({ x, ...rest }, index) => {
        const { label, Icon } = hobbiesFixture[index]
        const isSelected = selectedExperience === index

        return (
          <animated.div
            key={`history_option-${label}`}
            style={{ ...rest, transform: x.interpolate(x => `translate3d(${x}vw,0,0)`) }}
            css={style.hobby(isSelected)}
            onClick={() => onChange(index)}
          >
            <Icon css={style.hobbyVisual} />
            <label>{label}</label>
          </animated.div>
        )
      })}
    </div>
  )
}

const ExperienceDescription = ({ selectedExperience }) => {
  if (selectedExperience === null) {
    return <div css={style.descriptionContainer} />
  }

  const transitions = useTransition([hobbiesFixture[selectedExperience]], item => item.label, {
    from: { transform: 'translate(100vw, 0rem)' },
    enter: { transform: 'translate(0vw, 0rem)' },
    leave: { transform: 'translate(-100vw, 0rem)' },
  })

  return (
    <div css={style.descriptionContainer}>
      {
        transitions.map(({ item, props }) => (
          <animated.div
            key={`history_description-${item.label}`}
            style={props}
            css={style.description}
          >
            <div css={style.animationWrapper}>
              <Animation name={item.animation} css={style.hobbyAnimation} />
            </div>
            <p>
              {item.description}
            </p>
          </animated.div>
        ))
      }
    </div>
  )
}

const HobbiesInteractive = () => {
  const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(null)

  return (
    <>
      <HobbyPicker
        selectedExperience={selectedExperienceIndex}
        onChange={setSelectedExperienceIndex}
      />
      <ExperienceDescription
        selectedExperience={selectedExperienceIndex}
      />
      <BackgroundChanger
        selectedExperienceIndex={selectedExperienceIndex}
      />
    </>
  )
}

const BackgroundChanger = ({ selectedExperienceIndex }) => {
  const { backgroundColor } = hobbiesFixture[selectedExperienceIndex] || {}

  return <div css={style.dynamicBackground(backgroundColor)} />
}

const HobbiesSection = ({ theme }) => {
  return (
    <section css={style.sectionHobbies({ theme })}>
      <Title />
      <HobbiesInteractive />
    </section>
  )
}

export default HobbiesSection
