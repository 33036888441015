import { css } from '@emotion/core'
import { mqMax } from '~/styles/mixins/mediaQueries'
import randomFloat from '~/styles/animations/randomFloat'
import appearIn from '~/styles/animations/appearIn'

export const modal = css`
    overflow: hidden;
    text-align: center;
    height: 90%;
    max-height: 35rem;

    ${mqMax.sm} {
        padding: 1rem;
    }
`

export const title = css`
    font-size: 2.5rem;

    ${mqMax.sm} {
        font-size: 1.5rem
    }
`

export const body = css`
    font-size: 1.2rem;

    ${mqMax.sm} {
        font-size: 1rem
    }
`

export const screensContainer = css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    ${mqMax.sm} {
        margin-top: 10vw;
    }

    > div {
        position: relative;
    }
`

export const screenWrapper = css`
    ${randomFloat(30)}
`

export const imageMobile = css`
    height: 10rem;
    transform: rotate(-10deg);

    ${mqMax.sm} {
        height: 5rem;
    }
`

export const imageDesktop = css`
    height: 15rem;
    transform: rotate(10deg);
    
    ${mqMax.sm} {
        height: 10rem;
    }
`

const icon = css`
    position: absolute;
    font-size: 10rem;
    top: 0;
    left: 0;

    ${mqMax.sm} {
        font-size: 5rem;
    }

    ${appearIn(5)};
`

export const iconDeny = css`
    ${icon};
`

export const iconApprove = css`
    ${icon};
    left: 25%;

    ${mqMax.sm} {
        top: 15%;
    }
`

export const avatarContainer = css`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -9rem;
    left: 0;

    ${mqMax.sm} {
        bottom: -10rem;
    }
`