import { random } from '~/helpers/coordinatesHelper'

import Angular from '~/public/static/images/skills/Angular.svg'
import Cordova from '~/public/static/images/skills/Cordova.svg'
import Cypress from '~/public/static/images/skills/Cypress.svg'
import EmotionJS from '~/public/static/images/skills/EmotionJS.svg'
import Firebase from '~/public/static/images/skills/Firebase.svg'
import Git from '~/public/static/images/skills/Git.svg'
import HTML from '~/public/static/images/skills/HTML.svg'
import Ionic from '~/public/static/images/skills/Ionic.svg'
import JavaScript from '~/public/static/images/skills/JavaScript.svg'
import Jest from '~/public/static/images/skills/Jest.svg'
import MySQL from '~/public/static/images/skills/MySQL.svg'
import NextJS from '~/public/static/images/skills/NextJS.svg'
import Python from '~/public/static/images/skills/Python.svg'
import React from '~/public/static/images/skills/React.svg'
import SASS from '~/public/static/images/skills/SASS.svg'
import TypeScript from '~/public/static/images/skills/TypeScript.svg'
import Vue from '~/public/static/images/skills/Vue.svg'

const STYLE_FROM_DEFAULT = { left: '-50%' }

const tension = random(50, 100)

const technologies = [
  {
    key: 'Angular',
    Component: Angular,
    from: STYLE_FROM_DEFAULT,
    to: { left: '80%', top: '20%' },
    config: { tension },
  },
  {
    key: 'Cordova',
    Component: Cordova,
    from: STYLE_FROM_DEFAULT,
    to: { left: '53%', top: '27%' },
    config: { tension },
  },
  {
    key: 'Cypress',
    Component: Cypress,
    from: STYLE_FROM_DEFAULT,
    to: { left: '30%', top: '75%' },
    config: { tension },
  },
  {
    key: 'EmotionJS',
    Component: EmotionJS,
    from: STYLE_FROM_DEFAULT,
    to: { left: '38%', top: '47%' },
    config: { tension },
  },
  {
    key: 'Firebase',
    Component: Firebase,
    from: STYLE_FROM_DEFAULT,
    to: { left: '30%', top: '25%' },
    config: { tension },
  },
  {
    key: 'Git',
    Component: Git,
    from: STYLE_FROM_DEFAULT,
    to: { left: '17%', top: '60%' },
    config: { tension },
  },
  {
    key: 'HTML',
    Component: HTML,
    from: STYLE_FROM_DEFAULT,
    to: { left: '75%', top: '45%' },
    config: { tension },
  },
  {
    key: 'Ionic',
    Component: Ionic,
    from: STYLE_FROM_DEFAULT,
    to: { left: '46%', top: '75%' },
    config: { tension },
  },
  {
    key: 'JavaScript',
    Component: JavaScript,
    from: STYLE_FROM_DEFAULT,
    to: { left: '10%', top: '35%' },
    config: { tension },
  },
  {
    key: 'Jest',
    Component: Jest,
    from: STYLE_FROM_DEFAULT,
    to: { left: '58%', top: '49%' },
    config: { tension },
  },
  {
    key: 'MySQL',
    Component: MySQL,
    from: STYLE_FROM_DEFAULT,
    to: { left: '6%', top: '13%' },
    config: { tension },
  },
  {
    key: 'NextJS',
    Component: NextJS,
    from: STYLE_FROM_DEFAULT,
    to: { left: '80%', top: '80%' },
    config: { tension },
  },
  {
    key: 'Python',
    Component: Python,
    from: STYLE_FROM_DEFAULT,
    to: { left: '65%', top: '66%' },
    config: { tension },
  },
  {
    key: 'React',
    Component: React,
    from: STYLE_FROM_DEFAULT,
    to: { left: '65%', top: '3%' },
    config: { tension },
  },
  {
    key: 'SASS',
    Component: SASS,
    from: STYLE_FROM_DEFAULT,
    to: { left: '5%', top: '80%' },
    config: { tension },
  },
  {
    key: 'TypeScript',
    Component: TypeScript,
    from: STYLE_FROM_DEFAULT,
    to: { left: '22%', top: '7%' },
    config: { tension },
  },
  {
    key: 'Vue',
    Component: Vue,
    from: STYLE_FROM_DEFAULT,
    to: { left: '45%', top: '5%' },
    config: { tension },
  },
]

const skillLevel = {
  labels: [
    'Web Dev',
    'Mobile Dev',
    'Backend',
    'Security',
    'Blockchain',
  ],
  data: [
    90,
    70,
    60,
    55,
    10,
  ],
}

export default { technologies, skillLevel }
