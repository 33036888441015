import media from '~/constants/media'

const HISTORY = [
  {
    label: 'Antonine International School',
    image: media.history.ais,
    title: 'IB Student',
    startDate: 'N/A',
    endDate: '2013',
    location: 'Ajaltoun, LB',
    description: 'Graduated with an IB Diploma (High-level Biology, Psychology and Chemistry).',
  },
  {
    label: 'Notre Dame University',
    image: media.history.ndu,
    title: 'Computer Science Student',
    startDate: '2014',
    endDate: '2018',
    location: 'Zouk Mosbeh, LB',
    description: 'Graduated with BS in Computer Science. Was also in charge of the computer science division of the IEEE club.',
  },
  {
    label: 'ESW i+',
    image: media.history.eswiplus,
    title: 'Team Lead',
    startDate: 'Nov. 2017',
    endDate: 'Aug. 2018',
    location: 'Remote, LB',
    description: 'Was a tech and project lead, developing a currency and crypto trading platform. Duties included team guidance and project management, devOps, and back-end development.',
    tags: ['Agile', 'AngularTS', 'Firebase', 'MySQL', 'Cassandra', 'Redis', 'PHP', 'Cordova', 'Ionic', 'Python'],
    tagsColor: 'blue',
  },
  {
    label: 'Sowlutions',
    image: media.history.sowlutions,
    title: 'Software Engineer',
    startDate: 'Sept. 2018',
    endDate: 'Today',
    location: 'Remote, USA | Nahr El Kaleb, LB',
    description: 'Software engineer with a focus on web development. Duties also included mentoring newcomers on various technical subjects, some of which include code organization and cleanliness, project management, and logical problem solving.\n Currently on a contract basis.',
    tags: ['ReactJS', 'VueJS', 'Firebase', 'MySQL', 'Jest', 'Cypress', 'EmotionJS'],
    tagsColor: 'purple',
  },
  {
    label: 'Divz',
    image: media.history.divz,
    title: 'Founder',
    url: 'https://divz.io',
    startDate: 'Mar. 2021',
    endDate: 'Today',
    location: 'Remote, USA',
    description: 'Founded a company and successfully delivered over 10 customized software solutions with a 95% client satisfaction rate. I managed client relationships, ensuring clear communication and on-time delivery of projects. I coordinated tasks across frontend, backend, and middleware teams, and oversaw external resources to enhance product quality. My role involved balancing hands-on development with leadership responsibilities, ensuring seamless project execution and high-quality outcomes for diverse client needs.',
    tags: ['Software Solutions', 'Client Management', 'Project Coordination', 'Full-Stack Development', 'Task Delegation', 'Stakeholder Communication', 'Agile Project Management'],
    tagsColor: 'divz',
  },
  {
    label: 'Beatport',
    image: media.history.beatport,
    title: 'Software Engineer',
    startDate: 'May 2021',
    endDate: 'Today',
    location: 'Remote, USA',
    description: 'Led major code refactoring projects, modernizing legacy systems to improve performance and scalability. I introduced key productivity enhancements, optimizing development cycles and engineering solutions that saved the company over $100,000 annually. I also served as a primary resource for multiple projects, mentoring fellow engineers and providing clear guidance to ensure successful project delivery. My contributions significantly improved both code quality and team efficiency.\n Currently on a contract basis.',
    tags: ['ReactJS', 'ReactTS', 'Jest', 'Mobx', 'JWT', 'NodeJS', 'Express', 'Docker', 'Cross-Functional Collaboration', 'Cost Optimization'],
    tagsColor: 'beatport',
  },
]

export default HISTORY
