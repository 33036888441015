import React, { useState } from 'react'
import dynamic from 'next/dynamic'

const CodeDialer = dynamic(
  () => import('~/components/CodeDialer'),
  { ssr: false }
)
import isServer from '~/utils/isServer'
import Modal from '~/components/Modal'
import Animation from '~/components/Animation'

import * as style from './styles'

const CODE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
  'Enter',
]

const Message = (props) => (
  <Modal
    {...props}
  >
    <h2 css={style.title}>Wawawiza!</h2>
    <span css={style.body}>
      So you&apos;ve found the &apos;super secret&apos; easter egg. Nice work. <br /> <br />
      I haven&apos;t put anything here yet because I just don&apos;t have the time or energy to do it right now. <br /> <br />
      I had (have?) big plans to make a fun interactive mini-game here... But all in due time. <br /> <br />
      Oh, and thanks for visiting!
      <Animation name='easterEgg' css={style.animationEgg} />
    </span>
  </Modal>
)

/**
 * <Describe the EasterEgg component here>
 *
 * @component
 * @usage `import EasterEgg from '~/components/EasterEgg'`
 * @example
 * <Add an example of how EasterEgg is used here>
 */
const EasterEgg = () => {
  const [isShown, setIsShown] = useState(false)

  if (isServer) {
    return null
  }

  return (
    <div>
      <CodeDialer
        code={CODE}
        onSuccess={() => setIsShown(true)}
      />
      <Message
        isActive={isShown}
        onClose={() => setIsShown(false)}
      />
    </div>
  )
}

EasterEgg.propTypes = {
}

EasterEgg.defaultProps = {
}

export default EasterEgg
