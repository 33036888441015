import React from 'react'
import { inject, observer } from 'mobx-react'

import RevealFade from '~/components/RevealFade'

import * as style from './styles'

/**
 * <Describe the SectionSubtitle component here>
 *
 * @component
 * @usage `import SectionSubtitle from '~/components/SectionSubtitle'`
 * @example
 * <Add an example of how SectionSubtitle is used here>
 */
const SectionSubtitle = ({ rootStore, children, ...extra }) => {
  const { theme } = rootStore.stateStore

  return (
    <RevealFade>
      <h3 css={style.subtitle({ theme })} {...extra}>
        {children}
      </h3>
    </RevealFade>
  )
}

export default inject('rootStore')(observer(SectionSubtitle))
