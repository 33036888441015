
const blobs = {
  'blob-1': '/static/images/blobs/blob-1.svg',
  'blob-2': '/static/images/blobs/blob-2.svg',
  'blob-3': '/static/images/blobs/blob-3.svg',
  'blob-4': '/static/images/blobs/blob-4.svg',
  'blob-5': '/static/images/blobs/blob-5.svg',
  'blob-6': '/static/images/blobs/blob-6.svg',
  'blob-7': '/static/images/blobs/blob-7.svg',
  'blob-8': '/static/images/blobs/blob-8.svg',
  'blob-9': '/static/images/blobs/blob-9.svg',
}

const history = {
  ais: '/static/images/history/ais.png',
  eswiplus: '/static/images/history/eswiplus.png',
  ndu: '/static/images/history/ndu.png',
  sowlutions: '/static/images/history/sowlutions.png',
  beatport: '/static/images/history/beatport.png',
  divz: '/static/images/history/divz.png',
}

const hobbies = {
  computer: '/static/images/hobbies/computer.svg',
  drone: '/static/images/hobbies/drone.svg',
  game: '/static/images/hobbies/game.svg',
  map: '/static/images/hobbies/map.svg',
}

const skills = {
  Angular: '/static/images/skills/Angular.svg',
  Cordova: '/static/images/skills/Cordova.svg',
  Cypress: '/static/images/skills/Cypress.svg',
  EmotionJS: '/static/images/skills/EmotionJS.svg',
  Firebase: '/static/images/skills/Firebase.svg',
  Git: '/static/images/skills/Git.svg',
  HTML: '/static/images/skills/HTML.svg',
  Ionic: '/static/images/skills/Ionic.svg',
  JavaScript: '/static/images/skills/JavaScript.svg',
  Jest: '/static/images/skills/Jest.svg',
  MySQL: '/static/images/skills/MySQL.svg',
  NextJS: '/static/images/skills/NextJS.svg',
  Python: '/static/images/skills/Python.svg',
  React: '/static/images/skills/React.svg',
  SASS: '/static/images/skills/SASS.svg',
  TypeScript: '/static/images/skills/TypeScript.svg',
  Vue: '/static/images/skills/Vue.svg',
}

const animations = {
  arrowOnTarget: '/static/animations/arrowOnTarget.svg',
  burritoMunching: '/static/animations/burritoMunching.svg',
  burritoMunchingDark: '/static/animations/burritoMunchingDark.svg',
  camping: '/static/animations/camping.svg',
  coding: '/static/animations/coding.svg',
  dressup: '/static/animations/dressup.svg',
  drone: '/static/animations/drone.svg',
  easterEgg: '/static/animations/easterEgg.svg',
  fistBump: '/static/animations/fistBump.svg',
  floatingPizza: '/static/animations/floatingPizza.svg',
  gaming: '/static/animations/gaming.svg',
  hammerAnvil: '/static/animations/hammerAnvil.svg',
  heartBeat: '/static/animations/heartBeat.svg',
  lightBulb: '/static/animations/lightBulb.svg',
  mailInMailbox: '/static/animations/mailInMailbox.svg',
  morphingBlobs: '/static/animations/morphingBlobs.svg',
  musicDancing: '/static/animations/musicDancing.svg',
  serverLoading: '/static/animations/serverLoading.svg',
  serverReady: '/static/animations/serverReady.svg',
  shiningDiamond: '/static/animations/shiningDiamond.svg',
  signature: '/static/animations/signature.svg',
  signatureStatic: '/static/animations/signatureStatic.svg',
  signatureStaticWhite: '/static/animations/signatureStaticWhite.svg',
  signatureWhite: '/static/animations/signatureWhite.svg',
  travelWorld: '/static/animations/travelWorld.svg',
  wateringPlant: '/static/animations/wateringPlant.svg',
}

const audio = {
  'chat-incoming': '/static/audio/chat-incoming.mp3',
  'chat-outgoing': '/static/audio/chat-outgoing.mp3',
}

const misc = {
  'screen-desktop': '/static/images/misc/screen-desktop.svg',
  'screen-mobile': '/static/images/misc/screen-mobile.svg',
}

export default {
  blobs,
  history,
  hobbies,
  skills,
  animations,
  audio,
  misc,
}
