import { css } from '@emotion/core'
import colors from '~/styles/colors'

export const sectionContact = ({ theme }) => css`
  background: ${colors.theme[theme].backgroundColorAlt};
`

export const cardContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    margin: none;
  }
`

export const card = css`
  margin-bottom: 5rem;
`
